@import '../../../style/sass/main.scss';

.drawer-header {
    border-bottom: 0.063rem solid $border-color-1;
    background: $grey-7;
    min-height: 5.8rem !important;

    .drawer-icon-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // padding-left: 0.625rem;
    }

    .hidden {
        display: none !important;
    }
}

.sidebar-container {
    background: #F8F8F8;
    // height: 100% !important;

    .sidebar-list {
        padding: 0 !important;

        .sidebar-menu-list {
            display: block;

            .active-list {
                background: #E8F3FD;
                border-left: 0.313rem solid $primary-color;
            }

            .active-button {
                padding-left: 1rem !important;
            }

            .active-subbutton {
                padding-left: 2.4rem !important;
                // background-color: $highlight-color;
            }

            .sidebar-menu-button .sidebar-menu-subbutton {
                min-height: 3rem;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
            }

            .sidebar-menu-icon {
                font-size: 1.6rem;
            }

            .sidebar-menu-text {
                margin-left: 1.3rem;
                color: $secondary-color !important;

                span {
                    font-size: 1.25rem;
                }
            }

            .sidebar-menu-subbutton {
                padding-left: 3rem;
            }

            .sidebar-menu-subbutton {
                &:hover {
                    color: $primary-color !important;

                    // .sidebar-menu-text {
                    //     color: $primary-color !important;
                    // }

                    // .sidebar-menu-icon {
                    //     color: $primary-color !important;
                    // }
                }
            }

            .sidebar-menu-button {
                &:hover {
                    color: $primary-color !important;

                    // .sidebar-menu-text {
                    //     color: $primary-color !important;
                    // }

                    // .sidebar-menu-icon {
                    //     color: $primary-color !important;
                    // }
                }
            }

            .active-text {
                color: $primary-color !important;

                .MuiTypography-root {
                    color: $primary-color !important;
                }
            }

            .disabled-menu {
                color: $text-color !important;
            }
        }

        .active-menu-background {
            background: #ffe6d9;
        }

        div.primary-color-class.MuiBox-root.css-0 {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }

        div.secondary-color-class.MuiBox-root.css-0 {
            svg {
                path {
                    fill: $secondary-color;
                }
            }
        }
    }
}

.primary-color-container {
    color: $primary-color;
}

.secondary-color-container {
    color: $secondary-color;
}

.text-color-container {
    color: $text-color;
}

.copy-right {
    text-align: center;
    padding: 1rem;
    margin-top: auto;
}

.MuiDrawer-paper {
    background-color: $grey-7 !important;
    z-index: 101 !important
}

.copyright-symbol {
    font-size: 0.85rem;
}