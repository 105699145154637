@import "../../../style/sass/main.scss";

.header-container {
  position: fixed;
  height: 3rem !important;

  .MuiToolbar-root {
    background: $grey-7;
    border-bottom: 0.063rem solid $border-color-1;
    padding: 0;
    min-height: 5.8rem !important;
  }
}

.pd-lft {
  padding-left: 1.5rem !important;
}

.header-content {
  height: 6.25rem;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-logout-container {
    @include flexCenter;

    .header-logout-user {
      display: flex;
      flex-direction: column;
      // padding-right: 1rem;

      .header-user-name {
        font-weight: 600;
        font-size: 1.125rem;
        color: $secondary-color;
        line-height: 1.375rem;
      }
    }
  }

  .header-logout {
    width: 3rem;
    height: 3rem;
    @include flexCenter;
    @include cursorPointer;

    .header-logout-logo {
      color: $button-color;
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.header-logo {
  display: block;
  object-fit: contain;
  margin: 0 0.5rem;
  height: 4rem;
  transition: all 0.5s ease-out;
}

.header-menu-drawer {
  width: 2rem;
}

.company-logo-open {
  margin: 0rem;
}

.company-logo-close {
  margin: 0rem;
  padding-right: 1.2rem;
}

.header-button-section {
  height: 100%;
  display: flex;

  .header-buttons {
    all: unset;
    cursor: pointer;
    min-width: 8rem;
    height: 100%;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    color: $secondary-color;
    padding: 0 0.8rem;
    border-right: 0.125rem solid #ebebec;
  }

  .header-active {
    // background: $secondary-color;
    // color: $secondary-color;
  }

  .active-bar {
    height: 0.3rem;
    width: 100%;
    background-color: $primary-color;
    margin-top: -0.3rem !important;
  }

  .brd-left {
    border-left: 0.125rem solid #ebebec;
  }

  // .mrg-left {
  //     margin-left: 1.25rem;
  // }
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiAppBar-root {
  z-index: 98 !important;
}

.cursor-pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}