$primary-color: #E94F1C;
$secondary-color: #1D1D1B;
$button-color: #666666;

$text-color: #939598;
$text-color-1: #292666;
$text-color-2: #2A3F54;
$text-color-3: #8D939B;
$text-disabled: #ACB2BA;
$link-color: #F2F1FF;
$link-color-1: #F8F8F8;
$grey-1: #ACB2BA;
$grey-2: #D1D1D1;
$grey-3: #F2F2F2;
$grey-4: #F5F5F5;
$grey-5: #F1F1F1;
$grey-6: #FAFAFA;
$grey-7: #f8f8f8;
$white-color: white;
$black-color: #000;
$status-green: #1BD365;
$status-red: #FF3C3C;
$border-color: #EDEDED;
$border-color-1:#E9E9E9;
$highlight-color: #00000008;
$input-border-color: rgba(0, 0, 0, 0.15);
$role-permission-background: #efefef;
$role-permission-text: hsl(0, 0%, 20%);
$role-permission-cross-background: rgb(255, 189, 173);

// notification common component variables
$light-grey: #ACB2BA;
$table-header: #efefef;
$table-td-color: #212529;
$light-orange: #F39677