*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

// rem calculation 65/100 * 16 = 10.4px
//
html {
  font-size: 65% !important; // 1rem = 10.5px

  @media only screen and (min-width: 1600px) {
    font-size: 100% !important;
  }
}

body {
  box-sizing: border-box;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #939598;
  overflow-y: hidden;
  height: 100%;
  background: white !important;

  :not(:root):fullscreen::backdrop {
    background: white !important;
  }
}

/* CSS Document */
/* poppins-300 - latin */
@font-face {
  font-family: 'Euclid Circular Bold Italic';
  src: url('./font/euclid-circular-b-bold-italic.ttf');
}

@font-face {
  font-family: 'Euclid Circular Bold';
  src: url('./font/euclid-circular-b-bold.ttf');
}

@font-face {
  font-family: 'Euclid Circular Italic';
  src: url('./font/euclid-circular-b-italic.ttf');
}

@font-face {
  font-family: 'Euclid Circular Light Italic';
  src: url('./font/euclid-circular-b-light-italic.ttf');
}

@font-face {
  font-family: 'Euclid Circular Light';
  src: url('./font/euclid-circular-b-light.ttf');
}

@font-face {
  font-family: 'Euclid Circular Medium Italic';
  src: url('./font/euclid-circular-b-medium-italic.ttf');
}

@font-face {
  font-family: 'Euclid Circular Medium';
  src: url('./font/euclid-circular-b-meduim.ttf');
}

@font-face {
  font-family: 'Euclid Circular Regular';
  src: url('./font/euclid-circular-b-regular.ttf');
}

@font-face {
  font-family: 'Euclid Circular Semibold Italic';
  src: url('./font/euclid-circular-b-semi-bold-italic.ttf');
}

@font-face {
  font-family: 'Euclid Circular Semibold';
  src: url('./font/euclid-circular-b-semi-bold.ttf');
}

/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/poppins-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-300.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/poppins-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-regular.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-500.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/poppins-v20-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-600.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/poppins-v20-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-700.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/poppins-v20-latin-800.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/poppins-v20-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/poppins-v20-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/poppins-v20-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/poppins-v20-latin-800.svg#Poppins') format('svg');
  /* Legacy iOS */
}


/*poppins font-family*/

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/montserrat-v25-latin-800.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v25-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v25-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v25-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v25-latin-800.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

/*poppins font-family*/

/* Proxima Nova-regular */
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: local('ProximaNova'),
    url('./fonts/ProximaNova-Regular.otf') format('opentype'),
}

/* Proxima Nova-bold */
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: local(''),
    url('./fonts/ProximaNova-Bold.otf') format('opentype'),
}

a {
  text-decoration: none;
}