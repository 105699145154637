@import "./sass/main.scss";
$fontFamily: Montserrat, sans-serif;
$fontFamilyMonsterrat: "Montserrat";

.notification-dialog {

  .title-container {
    max-width: 70vw !important;

    .title {
      color: $secondary-color;
      font-family: $fontFamily;
      font-size: calc(1.3rem + .6vw);
      font-weight: 600;
      line-height: 1.2;
      max-width: 60vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .notifcation-title {
      color: $secondary-color;
    }
  }

  .close-button {
    width: 1.75rem !important;
    margin-top: 0.3rem;
    background-image: url("../assets/close-icon.svg");
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .sub-title {
    color: $primary-color;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .dialog-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view-all {
      color: $secondary-color;
      font-family: $fontFamilyMonsterrat;
      font-weight: 600;
      text-transform: none;
      font-size: 1rem;
      line-height: 1rem;
      padding: .9375rem 1.1875rem;
    }
  }

  .cards {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 0.5rem;
    }

    padding: 0.5rem 0;

    .badge {
      margin-top: 0.6rem;
    }

    .title-card {
      color: $secondary-color;
      font-family: $fontFamily;
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
      max-height: 2.625rem;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.25rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .sub-title {
      font-size: 1rem;
      color: $light-grey;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      text-align: right;
      margin: 0.2rem 0;
    }

    .read {
      font-weight: 400;
    }
  }
}

.notification-main-box {

  .MuiPaper-root {
    padding: 0.35rem 0 0.25rem 0.75rem !important;
    border-radius: 1.2rem !important;
  }

  .MuiDialogContent-root {
    padding: 0.75rem 0.75rem 0.75rem 0.4rem;
  }

  ;

  .MuiDialogActions-root {
    padding: 0.133rem 0.33rem 0.133rem 0;
  }

  ;

  .view-all {
    padding-left: 0 !important;
    margin-left: -0.25rem;
  }

}

.alert-dialog-main {
  top: 2.5rem !important;
  right: 0 !important;
  left: unset !important;
  bottom: unset !important;

  .MuiPaper-root {
    width: 35rem;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    color: $secondary-color;
    text-align: center;
  }
}

.alert-dialog {
  .MuiPaper-root {
    width: 70rem;
  }

  .MuiDialogContent-root {
    padding-left: 0;
  }

  ;

  .sticky-input-select {
    font-family: $fontFamily;
  }

  .no-record {
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: initial;
    color: $secondary-color;
    text-align: center;
  }

  .sticky-input-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1;
    position: sticky;
    background: #fff;
    z-index: 1;
    width: 100%;
    top: 0;
    height: 5rem;
  }
}

.action-show-all-dialog {
  .view-all {
    font-family: $fontFamilyMonsterrat !important;
    font-weight: 600;
    text-transform: initial;
    font-size: 1rem;
  }

  .MuiPaginationItem-root {
    font-family: $fontFamily !important;
    font-size: 1.16rem;
  }

  .MuiBox-root {
    font-family: $fontFamily !important;
    line-height: 1.42;
    font-size: 1.16rem;
  }

  .Mui-selected:hover {
    background-color: #29266614 !important;
  }
}

.scai-common-component-table-container {
  // width: 70rem;
  // max-width: 70rem;
  overflow-x: auto;

  .table-notification {
    border-collapse: collapse;
    display: table;
    height: auto;
    overflow-x: auto;
    width: 100%;

    tr {
      th {
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          cursor: pointer;
        }
      }
    }

    th,
    td {
      word-break: break-word;
      white-space: nowrap;
      padding: 0.6rem 1.3125rem;
      border: 0.125rem solid $secondary-color;
      font-family: Montserrat, sans-serif;
    }

    th {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      color: $secondary-color;
      background: $table-header;
      text-align: center;
      vertical-align: middle;
    }

    td {
      color: $table-td-color;
      font-size: 1.1rem;
      line-height: 1.25rem;
    }
  }
}

.circular-loader {
  color: $text-color !important;
}